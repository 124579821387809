<template>
  <div class="capital">
    <div class="capital-all">
      <div class="title">
        <div @click="goBack" v-if="roleType == 1">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="title-item">资金收入</div>
      </div>
      <div class="sum">
        <p class="sum-left">今日收入（元）</p>
        <div class="sum-right">
          <div class="right-num">{{ todayObj.totalCapital }}</div>
          <p class="right-text" @click="handleLookData">查看历史收入</p>
        </div>
      </div>
    </div>
    <div class="pie">
      <div class="title">
        <div class="title-text">今日收入明细</div>
        <div class="title-time">时段：00:00—23:59</div>
      </div>
      <div class="flex">
        <div class="flex-left">
          <div class="left-g">
            <div class="g-show"></div>
            <div class="g-item">
              现金金额：<p>{{ todayObj.cash }}</p>
            </div>
          </div>
          <div class="left-b">
            <div class="b-show"></div>
            <div class="b-item">
              移动金额：<p>{{ todayObj.mobile }}</p>
            </div>
          </div>
        </div>
        <div class="flex-right">
          <div class="right-all">
            <div id="pie"></div>
            <div class="all-show">
              <p>¥</p>
              <p>{{todayObj.totalCapital}}</p>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="bar">
      <h3>金额概况</h3>
      <div class="title">
        <div class="title-left">
          <img src="~@/assets/img/total/ico.png" />
          <div class="item-sum">
            充值金额：<span>{{ moneyObj.networkFee }}</span>
          </div>
        </div>
        <div class="title-right">
          <img src="~@/assets/img/total/ico.png" />
          <div class="item-sum">
            商品金额：<span>{{ moneyObj.sale }}</span>
          </div>
        </div>
      </div>
      <div id="bar"></div>
    </div>
    <div class="line">
      <div class="title">
        <div>近7天资金收入走势图</div>
        <div>时段：{{ startTimeShow }}—{{ endTimeShow }}</div>
      </div>
      <div id="line"></div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { Toast, TreeSelect } from "vant";
import Footer from "@/components/foot.vue";
export default {
  name: "capital",
  components: {
    Footer
  },
  data() {
    return {
      startTimeShow: "",
      endTimeShow: "",
      roleType: 0, 
      shopId: "",
      todayObj: {}, // 今日收入保存
      moneyObj: {}, // 金额概况保存
      trendObj: [] // 7天走势图保存
    };
  },
  mounted() {
    this.roleType = localStorage.roleType;
    this.shopId = this.$route.query.shopId || localStorage.shopId;
    this.handleFirstData();
    this.getAmountOfStatus();
    this.getTrend();
  },
  methods: {
    getTrend() {
      let _this = this;
      var myDate = new Date(); //获取今天日期
      myDate.setDate(myDate.getDate() - 7);
      let endTime = this.parseTime(new Date()-24*60*60*1000, "{y}-{m}-{d}");
      this.endTimeShow= this.parseTime(new Date()-24*60*60*1000, "{m}.{d}");
      let startTime= this.parseTime(new Date(myDate), "{y}-{m}-{d}");
      this.startTimeShow = this.parseTime(new Date(myDate), "{m}.{d}");
      let params = {
        shopId: this.shopId,
        startTime: startTime,
        endTime: endTime
      };
      _this.$API.getTrend(params).then(function(res) {
        if (res.code == 200) {
          _this.trendObj = res.data;
          _this.handleLineData();
        } else {
          Toast(res.msg);
        }
      });
    },
    getAmountOfStatus() {
      let _this = this;
      _this.$API.getAmountOfStatus({ shopId: this.shopId }).then(function(res) {
        if (res.code == 200) {
          _this.moneyObj = res.data;
          _this.handlebarData();
        } else {
          Toast(res.msg);
        }
      });
    },
    handleFirstData() {
      let dataTime = this.parseTime(new Date(), "{y}-{m}-{d}");
      let _this = this;
      _this.$API
        .getCapital({ shopId: this.shopId, date: dataTime })
        .then(function(res) {
          if (res.code == 200) {
            _this.todayObj = res.data;
            _this.handlepieData();
          } else {
            Toast(res.msg);
          }
        });
    },
    // 环形图
    handlepieData() {
      //初始化
      let myChart = this.$echarts.init(document.getElementById("pie"));
      let num = this.todayObj.totalCapital;
      // 回执图表
      myChart.setOption({
        tooltip: {
          show: false
        },
        legend: { show: false },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["72%", "55%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "20",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: this.todayObj.cash,
                name: "现金收入",
                itemStyle: { color: "#5CD08C" }
              },
              {
                value: this.todayObj.mobile,
                name: "移动收入",
                itemStyle: { color: "#007EFE" }
              }
            ]
          }
        ]
      });
      window.onresize = myChart.resize;
    },
    // 柱形图
    handlebarData() {
      let that = this;
      let saleArr = [],networkFeeArr=[];
      saleArr.push(that.moneyObj.mobileSale,that.moneyObj.mobileNetworkFee)
      networkFeeArr.push(that.moneyObj.cashSale,that.moneyObj.cashNetworkFee)
      //初始化
      let myChart = that.$echarts.init(document.getElementById("bar"));
      // 回执图表
      myChart.setOption({
        tooltip: {
          show: false
        },
        color: ["#4DBB81", "#6D8EFA"],
        legend: { show: false },
        grid: {
          top: "2%",
          left: "0%",
          right: "6%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          axisLine: { 
            onZero: false ,
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#EDEDED", "#EDEDED"],
              type: 'dashed' // y轴分割线类型
            },
          },
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#EDEDED", "#EDEDED"],
              type: 'dashed' // y轴分割线类型
            }
          },
          type: "category",
          data: ["商品", "充值"],
          axisTick: {
            show: false
          },
          axisLine: { 
            onZero: false ,
            show: false
          },
        },
        series: [
          {
            name: "充值",
            type: "bar",
            data: networkFeeArr, //设置柱状图大小
            barWidth: 16,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  // position: 'right',
                  // valueAnimation: true,
                  textStyle: {
                    color: "#fff"
                  },
                  formatter: function(params) {
                    if (params.value == 0) {
                      return params.value;
                    }
                  }
                },
                barBorderRadius: 4,
                color: function(params) {
                  var colorList = [
                    ["#4DBB81", "#61D890"],
                  ];
                  var index = params.dataIndex;
                  if (params.dataIndex >= colorList.length) {
                    index = params.dataIndex - colorList.length;
                  }
                  // return colorList[params.dataIndex]
                  // l
                  return new that.$echarts.graphic.LinearGradient(1, 0, 0, 1, [
                    {
                      offset: 0,
                      color: colorList[index][0]
                    },
                    {
                      offset: 1,
                      color: colorList[index][1]
                    }
                  ]);
                }
              }
            }
          },
          {
            name: "商品",
            type: "bar",
            data: saleArr, //设置柱状图大小
            barWidth: 16,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  // position: 'right',
                  // valueAnimation: true,
                  textStyle: {
                    color: "#fff"
                  },
                  formatter: function(params) {
                    if (params.value == 0) {
                      return params.value;
                    }
                  }
                },
                barBorderRadius: 4,
                color: function(params) {
                  var colorList = [
                    ["#6D8EFA", "#2765F2"]
                  ];
                  var index = params.dataIndex;
                  if (params.dataIndex >= colorList.length) {
                    index = params.dataIndex - colorList.length;
                  }
                }
              }
            }
          },
        ]
      });
      window.onresize = myChart.resize;
    },
    // handlebarData() {
    //   let that = this;
    //   let saleArr = [],networkFeeArr=[];
    //   saleArr.push(that.moneyObj.cashSale,that.moneyObj.mobileSale)
    //   networkFeeArr.push(that.moneyObj.cashNetworkFee,that.moneyObj.mobileNetworkFee)
    //   //初始化
    //   let myChart = that.$echarts.init(document.getElementById("bar"));
    //   // 回执图表
    //   myChart.setOption({
    //     tooltip: {
    //       show: false
    //     },
    //     color: ["#4DBB81", "#6D8EFA"],
    //     legend: { show: false },
    //     grid: {
    //       top: "2%",
    //       left: "3%",
    //       right: "4%",
    //       bottom: "3%",
    //       containLabel: true
    //     },
    //     xAxis: {
    //       type: "value",
    //       axisTick: {
    //         show: false,
    //       },
    //       axisLine: { onZero: false },
    //       boundaryGap: [0, 0.01],
    //       splitLine: {
    //         show: true,
    //         lineStyle: {
    //           color: ["#EDEDED", "#EDEDED"],
    //           type: 'dashed' // y轴分割线类型
    //         }
    //       },
    //     },
    //     yAxis: {
    //       axisLine: { 
    //         onZero: false ,
    //         show: false
    //       },
    //       type: "category",
    //       data: ["商品", "充值"],
    //       axisTick: {
    //         show: false
    //       },
    //       splitLine: {
    //         show: true,
    //         lineStyle: {
    //           color: ["#EDEDED", "#EDEDED"],
    //           type: 'dashed' // y轴分割线类型
    //         }
    //       },
    //     },  

    //     series: [
    //       {
    //         name: "充值",
    //         type: "bar",
    //         data: networkFeeArr, 
    //         barWidth: 16,//设置柱状图大小
    //         itemStyle: {
    //           normal: {
    //             barBorderRadius: 4,
    //             label: {
    //               show: true,
    //               position: 'right',
    //               valueAnimation: true,
    //               textStyle: {
    //                 color: "#656565"
    //               },
    //               formatter: function(params) {
    //                 if (params.value == 0) {
    //                   return params.value;
    //                 }
    //               }
    //             },
    //             color: function(params) {
    //               var colorList = [
    //                 ["#61D890", "#4DBB81"],
    //               ];
    //               var index = params.dataIndex;
    //               if (params.dataIndex >= colorList.length) {
    //                 index = params.dataIndex - colorList.length;
    //               }
    //               // return colorList[params.dataIndex]
    //               // l
    //               return new that.$echarts.graphic.LinearGradient(1, 0, 0, 1, [
    //                 {
    //                   offset: 0,
    //                   color: colorList[index][0]
    //                 },
    //                 {
    //                   offset: 1,
    //                   color: colorList[index][1]
    //                 }
    //               ]);
    //             }
    //           }
    //         }
    //       },
    //       {
    //         name: "商品",
    //         type: "bar",
    //         data: saleArr, 
    //         barWidth: 16,//设置柱状图大小
    //         itemStyle: {
    //           normal: {
    //             barBorderRadius: 4,
    //             label: {
    //               show: true,
    //               position: 'right',
    //               valueAnimation: true,
    //               textStyle: {
    //                 color: "#656565"
    //               },
    //               formatter: function(params) {
    //                 if (params.value == 0) {
    //                   return params.value;
    //                 }
    //               }
    //             },
    //             color: function(params) {
    //               var colorList = [
    //                 ["#2765F2", "#6D8EFA"]
    //               ];
    //               var index = params.dataIndex;
    //               if (params.dataIndex >= colorList.length) {
    //                 index = params.dataIndex - colorList.length;
    //               }
    //               // return colorList[params.dataIndex]
    //               // l
    //               return new that.$echarts.graphic.LinearGradient(1, 0, 0, 1, [
    //                 {
    //                   offset: 0,
    //                   color: colorList[index][0]
    //                 },
    //                 {
    //                   offset: 1,
    //                   color: colorList[index][1]
    //                 }
    //               ]);
    //             }
    //           }
    //         }
    //       },
    //     ]
    //   });
    //   window.onresize = myChart.resize;
    // },
    // 折线图
    handleLineData() {
      let that = this;
      let valArr = [];
      let textArr = [];
      if (that.trendObj.length != 0) {
        for (let i in that.trendObj) {
          valArr.push(that.trendObj[i].money);
          textArr.push(that.trendObj[i].time);
        }
      }
      //初始化
      let myChart = that.$echarts.init(document.getElementById("line"));
      // 回执图表
      myChart.setOption({
        tooltip: {
          show: false
        },
        color: ["#4DBB81", "#6D8EFA"],
        legend: { show: false },
        grid: {
          top: "6%",
          left: "1%",
          right: "1%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#EDEDED", "#EDEDED"],
              type: 'dashed' // x轴分割线类型
            },
          },
          axisLabel: {
            interval:0 ,
            fontSize: 12
          },

          axisLine: { 
            onZero: false ,
            show: false
          },

          axisTick: {
            show: false,
          },
          type: "category",
          data: textArr
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#EDEDED", "#EDEDED"],
              type: 'dashed' // y轴分割线类型
            },
          }
        },
        series: [
          {
            data: valArr,
            type: "line",
            smooth: true
          }
        ]
      });
      window.onresize = myChart.resize;
    },
    goBack() {
      this.$router.push({ name: "Store" });
    },
    // 查看历史收入
    handleLookData() {
      this.$router.push({ name: "History", query: { type: 1 } });
    }
  }
};
</script>
<style lang="scss" scoped>
.capital {
  position: relative;
  padding-bottom: 100px;
  .capital-all {
    height: 380px;
    color: #ffffff;
    padding: 30px;
    margin-bottom: 70px;
    background: url("~@/assets/img/capitalBg.png");
    background-size: 100% 100%;
    .title {
      display: flex;
      align-items: center;
      i {
        font-size: 30px;
      }
      .title-item{
        flex: 5;
        text-align: center;
        font-size: 38px;

      }
    }
    .sum {
      margin-top: 76px;
      .sum-left {
        font-size: 26px;
      }
      .sum-right {
        display: flex;
        margin-top: 26px;
        .right-text {
          margin-left: auto;
          margin-top: 20px;
          font-size: 24px;
        }
        .right-num {
          font-weight: bold;
          font-size: 56px;
        }
      }
    }
  }
  .pie {
    position: absolute;
    width: 510px;
    height: 300px;
    background: #ffffff;
    box-shadow: 0px 2px 25px 1px rgba(52, 100, 224, 0.1);
    border-radius: 10px;
    padding: 35px 20px 35px 47px;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    z-index: 33;

    // padding: 30px;
    > div {
      display: flex;
    }
    .title {
      .title-text {
        font-size: 28px;
      }
      .title-time {
        color: #afb6cd;
        font-size: 24px;
        margin-left: auto;
        margin-top: 7px;
      }
    }
    .flex {
      display: flex;
      align-items: center;
      .flex-left {
        font-size: 28px;
        .left-g {
          display: flex;
          // align-items: center;
          .g-show {
            width: 14px;
            height: 14px;
            margin-top: 12px;
            background: #5cd08c;
            border-radius: 4px;
          }
          .g-item {
            margin-left: 12px;
          }
        }
        .left-b {
          display: flex;
          // align-items: center;
          margin-top: 33px;
          .b-show {
            width: 14px;
            height: 14px;
            margin-top: 12px;
            background: #007efe;
            border-radius: 4px;
          }
          .b-item {
            margin-left: 12px;
          }
        }
      }
      .flex-right {
        margin-left: auto;
        .right-all{
          width: 280px;
          height: 280px;
          position: relative;
          .all-show{
            display: flex;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            z-index: 33;
            >p:nth-child(2){
              font-size: 28px;
              margin-left: 6px;
              font-weight: bold;
            }
            >p:nth-child(1){
              font-size: 22px;
              margin-top: 5px;
            }
          }
        }
        #pie {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .bar {
    padding: 20px;
    margin-top: 50%;

    h3 {
      color: #1a1a1a;
      font-size: 30px;
    }
    .title {
      margin: 36px 0;
      display: flex;
      > div {
        display: flex;
        align-items: center;
        font-size: 26px;
        color: #000;
      }

      img {
        width: 16px;
        height: 18px;
      }
      .title-left {
      }
      .item-sum {
        margin-left: 12px;
      }
      .title-right {
        margin-left: 56px;
      }
    }
    #bar {
      width: 100%;
      height: 260px;
    }
  }
  .line {
    padding: 35px;
    border-top: 16px solid #f8f6f9;
    .title {
      display: flex;
      > div:nth-child(1) {
        font-size: 30px;
        color: #000;
      }
      > div:nth-child(2) {
        font-size: 24px;
        color: #afb6cd;
        margin-left: auto;
        margin-top: 10px;
      }
    }
    #line {
      width: 100%;
      height: 300px;
      margin-top: 50px;
    }
  }
}
</style>
